<template>
    <el-header class="head" height="120px">
        <div class="title">
            <label >中小学生心理测评SaaS系统（校园版）</label>
        </div>
        <div class="time">
            <time-countdown v-if="loginType === 'Student'"></time-countdown>
        </div>
        <div class="user">
            <el-avatar :size="36" src="static/main/photo.png"></el-avatar>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                你好,{{user.stu_name}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="update">修改密码</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

        </div>
        <div class="user exit" @click="exit">
            <el-image style="height: 28px;width: 28px;" src="static/main/exit.png"></el-image>
            <a>退出</a>
        </div>

        <el-dialog
                title="修改密码"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="密码" prop="Password">
                    <el-input type="password" v-model="ruleForm.Password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="verPwd">
                    <el-input type="password" v-model="ruleForm.verPwd" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="updatePassword" >确 定</el-button>
            </span>
        </el-dialog>

    </el-header>
</template>

<script>
    import setting from "@/config/setting";
    import {updatePassword, signOut} from "@/api/user";
    import TimeCountdown from "@/components/countdown";

    export default {
        name: "layout-head",
        components: {TimeCountdown},
        data(){
            return {
                user: {},
                loginType:null,
                dialogVisible: false,
                ruleForm:{
                    Password:'',
                    verPwd:''
                },
                rules:{
                    Password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                    verPwd: [
                        {required: true, message: '请再次输入密码', trigger: 'blur'},
                    ],
                }


            }
        },
        mounted() {
            this.user = setting.takeUser();
            /*是否加载计时器*/
            this.loginType = localStorage.getItem("login_type");

        },
        methods: {
            exit(){
                let _this = this;
                signOut().then((res)=> {
                    if(res.code == 200){
                        _this.$message.success("退出成功")
                        setting.clear();
                        setTimeout(()=>{
                            setting.clear();
                            _this.$router.push("/login")
                        },1000)
                    }else {
                        _this.$message.success(res.msg)
                    }
                })
            },
            handleCommand(command){
                if(command === 'update'){
                    this.dialogVisible = true;
                }
            },
            handleClose(){
                this.dialogVisible = false;
            },
            updatePassword(){
                let _this = this;
                updatePassword(this.ruleForm).then((res) => {
                    if(res.code === 200){
                        _this.$message.success("密码修改成功");
                        this.dialogVisible = false;
                    }else {
                        _this.$message.error(res.msg);
                    }
                    _this.ruleForm = {
                        Password:'',
                        verPwd:''
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .head{
        background-color: #06a7f8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .head .title{
        font-size: 30px;
        color: #ffffff;
        margin: 0 30px 0 30px;
    }
    .head .user{
        font-size: 15px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 15px 0 15px;
    }

    .head .user label,a{
        margin-left: 10px;
        letter-spacing: 2px;
        font-size: 16px;
    }
    .head .time{
        font-size: 15px;
        color: #ffffff;
        margin: 0 10px 0 10px;
    }
    .head .exit{
        cursor: pointer;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #ffffff;
        font-size: 16px;
        margin-left: 5px;
    }
    .el-dropdown-menu__item{
        line-height: 20px;
    }
</style>