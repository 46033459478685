import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress';
import LayoutIndex from '@/layout/index'

import LayoutMain from '@/layout/main';
import LayoutEvaluate from '@/layout/start';

import setting from "@/config/setting";


Vue.use(VueRouter)

let whiteList = ['/login']

const routes = [
    {
        path: '/test',
        name: 'test',
        component: LayoutEvaluate,
        children: [
            {
                path: '/test/start',
                component: () => import('@/views/test/start'),
            }
        ]
    },
    {
        path: '/test',
        name: 'test',
        component: LayoutIndex,
        children: [
            {
                path: '/test/index',
                component: () => import('@/views/test/test'),
            },
            {
                path: '/test/log',
                component: () => import('@/views/test/log'),
            },
            {
                path: '/test/question',
                component: () => import('@/views/test/question'),
            }
        ]
    },

    {
        path: '/',
        name: 'main',
        component: LayoutMain,
        children: [
            {
                path: '/',
                component: () => import('@/views/main/index'),
            },
            {
                path: '/main/test',
                component: () => import('@/views/test/test'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login2'),
        meta: {title: '登录'}
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})


// 路由守卫
router.beforeEach((to, from, next) => {
    NProgress.start();
    updateTitle(to);
    let token = setting.takeToken();
    if (token) {
        next();
    } else if (whiteList.includes(to.path)) {
        next();
    } else {
        next({path: '/login', query: to.path === '/' ? {} : {from: to.path}});
    }
    NProgress.done(true);
});

// router.afterEach(() => {
//   setTimeout(() => {
//     NProgress.done(true);
//   }, 300);
// });


export default router


/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle(route) {
    if (!route.path.startsWith('/redirect/')) {
        let names = [];
        if (route && route.meta && route.meta.title) {
            names.push(route.meta.title);
        }
        const appName = process.env.VUE_APP_NAME;
        if (appName) {
            names.push(appName);
        }
        document.title = names.join(' - ');
    }
}