<template>
    <el-container>
        <layout-head></layout-head>
        <el-main>
            <router-view />
        </el-main>
        <el-footer style="height: 0px">

        </el-footer>
    </el-container>
</template>

<script>
    import LayoutHead from "@/components/head";
    export default {
        name: "layout-evaluate",
        components: {LayoutHead}
    }
</script>

<style scoped>
    .el-main{
        height: 100%;
        width: 100%;
        background-image: url("~@/assets/image/evaluate/school_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        /*padding: 100px 360px 50px 360px;*/
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-container{
        height: 100%;
    }
    .el-footer{
        background-color: #06a7f8;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        font-size: 20px;
    }

</style>