import { axios } from '@/config/axios-config'


/*登录*/
export function doLogin (parameter) {
    return axios({
        url: '/login',
        method: 'post',
        params: parameter
    })
}

/*修改密码*/
export function updatePassword (parameter) {
    return axios({
        url: '/updatePWD',
        method: 'post',
        params: parameter
    })
}

/*退出登录*/
export function signOut (parameter) {
    return axios({
        url: '/loginOut',
        method: 'post',
        params: parameter
    })
}


